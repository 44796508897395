import AboutUsIcon from 'images/SimpleGreyIcons/AboutUs.svg'
import ProductsAndServicesIcon from 'images/SimpleGreyIcons/ProductsServices.svg'
import TheHumansIcon from 'images/SimpleGreyIcons/TheHumans.svg'
import ToolsOfTheTradeIcon from 'images/SimpleGreyIcons/ToolsTrade.svg'
import BeforeYouStartIcon from 'images/SimpleGreyIcons/BeforeYouStart.svg'
import YourFirstDaysIcon from 'images/SimpleGreyIcons/YourFirstDay.svg'
import YourEveryDayIcon from 'images/SimpleGreyIcons/YourEveryDay.svg'
import ThePerksOfBeingAWindscriberIcon from 'images/SimpleGreyIcons/ThePerks.svg'
import TimeOffIcon from 'images/SimpleGreyIcons/TimeOff.svg'
import RolesAndResponsibilitiesIcon from 'images/SimpleGreyIcons/RolesResponsibilities.svg'
import HowToSucceedIcon from 'images/SimpleGreyIcons/HowToSucceed.svg'
import AppendixAIcon from 'images/SimpleGreyIcons/AppendixA.svg'

export default {
  'About Us': AboutUsIcon,
  'Products and Services': ProductsAndServicesIcon,
  'The Humans': TheHumansIcon,
  'Tools of the Trade': ToolsOfTheTradeIcon,
  'Before You Start': BeforeYouStartIcon,
  'Your First Day(s)': YourFirstDaysIcon,
  'Your Every Day': YourEveryDayIcon,
  'The Perks of Being a Windscriber': ThePerksOfBeingAWindscriberIcon,
  'Time Off': TimeOffIcon,
  'Roles and Responsibilities': RolesAndResponsibilitiesIcon,
  'How to Succeed': HowToSucceedIcon,
  'Appendix A - Glossary of Terms': AppendixAIcon,
  'Appendix C - In-Office Policy': AboutUsIcon,
}
