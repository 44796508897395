import React, { ReactElement, useState } from 'react'
import { Flex } from 'theme-ui'
import NavBar from 'components/NavBar/NavBar'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import MainHeading from 'components/MainHeading'
import ParagraphWithBigFirstLetter from 'components/ParagraphWithBigFirstLetter'
import TopBar from 'components/MobileTabletTopBar/TopBar'
import { Helmet } from 'react-helmet'

export default function HandbookWrapper({
  data,
}: {
  data: GatsbyTypes.HandbookMdxQuery
}): ReactElement {
  const mdxNode = data.allMdx.nodes[0]
  const [isNavBarOpen, setIsNavBarOpen] = useState(false)

  return (
    <>
      <Helmet>
        <title>Windscribe Employee Handbook</title>
        <meta property="og:title" content="Windscribe Employee Handbook" />
        <meta property="og:site_name" content="Windscribe Employee Handbook" />
        <meta property="og:description" content="The Windscribe Employee Handbook." />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="en_US" />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
      </Helmet>

      <Flex
        sx={{
          width: '100%',
          backgroundColor: '#020D1C',
        }}
      >
        <TopBar isNavBarOpen={isNavBarOpen} setIsNavBarOpen={setIsNavBarOpen} />
        <NavBar
          isOpen={isNavBarOpen}
          tableOfContents={mdxNode.tableOfContents}
          setIsNavBarOpen={setIsNavBarOpen}
        />

        <Flex sx={{ ml: [0, 0, '320px'], width: '100%', justifyContent: 'center' }}>
          <Flex
            sx={{
              flexDirection: 'column',
              maxWidth: '98rem',
              mx: ['2.4rem', '4.8rem'],
              color: 'rgba(255, 255, 255, 0.8)',
              lineHeight: '2.4rem',
              letterSpacing: '0.4px',
              fontSize: '1.6rem',
              a: {
                color: 'inherit',
                textDecoration: 'underline',
              },
              h2: {
                m: 0,
                fontSize: '4.8rem',
                backgroundImage: 'linear-gradient(to right, #58ff8d -5%, #5dffe2 105%)',
                backgroundClip: 'text',
                letterSpacing: '0.4rem',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                textTransform: 'uppercase',
                lineHeight: '48px',
              },
              h3: {
                mb: '1rem',
                fontSize: '2.1rem',
                fontWeight: 500,
                color: 'white',
              },
              h4: {
                mb: '1rem',
                fontSize: '1.8rem',
                fontWeight: 500,
                color: 'white',
              },
            }}
          >
            {/* @ts-ignore */}
            <MDXRenderer components={{ MainHeading, ParagraphWithBigFirstLetter }}>
              {mdxNode.body}
            </MDXRenderer>
          </Flex>
        </Flex>
      </Flex>
    </>
  )
}

export const pageQuery = graphql`
  query HandbookMdx {
    allMdx {
      nodes {
        tableOfContents
        body
      }
    }
  }
`
