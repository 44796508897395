import Svg from 'components/Svg'
import React, { Dispatch, ReactElement, SetStateAction } from 'react'
import { Flex } from 'theme-ui'
import NavBarHeading from './NavBarHeading'
import NavBarItem, { NavBarChildItem } from './NavBarItems'
import sectionHeadingIconMap from './sectionHeadingIconMap'

export default function NavBar({
  tableOfContents,
  isOpen,
  setIsNavBarOpen,
}: {
  tableOfContents: GatsbyTypes.Mdx['tableOfContents']
  isOpen: boolean
  setIsNavBarOpen: Dispatch<SetStateAction<boolean>>
}): ReactElement {
  return (
    <Flex
      className="hide-scrollbar"
      sx={{
        transform: [
          isOpen ? 'translateX(0)' : 'translateX(-63rem)',
          isOpen ? 'translateX(0)' : 'translateX(-63rem)',
          'none',
        ],
        transition: 'all .2s ease-in-out',
        flexDirection: 'column',
        width: ['63rem', '32rem', '32rem'],
        color: 'white',
        flexShrink: 0,
        position: 'fixed',
        height: '100%',
        overflowY: 'auto',
        backgroundColor: '#091523',
        maxWidth: '100%',
      }}
    >
      <Flex
        sx={{
          flexDirection: 'column',
          px: '1.6rem',
          padding: '24px 24px 32px',
          fontSize: '1.6rem',
        }}
      >
        <NavBarHeading setIsNavBarOpen={setIsNavBarOpen} shouldShowCloseButton={true} />

        <NavBarItem title={'Welcome to Windscribe'} isSelected={true} />

        {/* @ts-ignore */}
        {tableOfContents.items.map(content => {
          return (
            <NavBarItem
              key={content.title}
              title={content.title}
              icon={sectionHeadingIconMap[content.title]}
              isSelected={content.title === 'Welcome to Windscribe'}
            >
              {content.items &&
                content.title !== 'Appendix A - Glossary of Terms' &&
                content.items.map(childContent => (
                  <NavBarChildItem
                    key={childContent.title}
                    title={childContent.title}
                    setIsNavBarOpen={setIsNavBarOpen}
                  />
                ))}
            </NavBarItem>
          )
        })}
      </Flex>
    </Flex>
  )
}
