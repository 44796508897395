import NavBarHeading from 'components/NavBar/NavBarHeading'
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Flex } from 'theme-ui'
import MenuIcon from 'images/MiscIcons/menu.svg'
import Svg from 'components/Svg'

interface TopBarProps {
  isNavBarOpen: boolean
  setIsNavBarOpen: Dispatch<SetStateAction<boolean>>
}
export default function TopBar({ isNavBarOpen, setIsNavBarOpen }: TopBarProps) {
  return (
    <Flex
      sx={{
        display: ['flex', 'flex', 'none'],
        height: '9rem',
        alignItems: 'center',
        width: '100%',
        position: 'fixed',
        backgroundColor: '#091523',
        px: '1.2rem',
        justifyContent: 'space-between',
      }}
    >
      <Flex sx={{ display: ['flex', 'none'], flex: 1 }} />
      <NavBarHeading setIsNavBarOpen={setIsNavBarOpen} shouldShowCloseButton={false} />
      <Flex
        sx={{
          cursor: isNavBarOpen ? 'normal' : 'pointer',
          opacity: isNavBarOpen ? 0 : 1,
          transition: 'opacity 0.2s ease-in-out',
          flex: 1,
          justifyContent: 'flex-end',
        }}
      >
        <Svg
          svg={MenuIcon}
          fill={'rgba(255, 255, 255, 0.5)'}
          onClick={() => {
            setIsNavBarOpen(true)
          }}
        />
      </Flex>
    </Flex>
  )
}
