import Svg from 'components/Svg'
import React, { Dispatch, ElementType, ReactNode, SetStateAction, useState } from 'react'
import { Flex, Text } from 'theme-ui'
import DownArrow from 'images/MiscIcons/down-chevron.svg'

interface NavBarItemProps {
  title: string
  isSelected?: boolean
  children?: ReactNode
  icon?: ElementType
}

export default function NavBarItem({ title, isSelected, children, icon }: NavBarItemProps) {
  return children ? (
    <AccordionNavBarItem title={title} isSelected={isSelected} icon={icon}>
      {children}
    </AccordionNavBarItem>
  ) : (
    <NonAccordionNavBarItem title={title} isSelected={isSelected} icon={icon} />
  )
}

function NonAccordionNavBarItem({ title, isSelected, icon }: NavBarItemProps) {
  return (
    <a
      href={`#${title
        .replace(/[!"’#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/g, '')
        .replace(/\s+/g, '-')
        .toLowerCase()}`}
    >
      <Flex
        sx={{
          cursor: 'pointer',
          alignItems: 'center',
          p: title === 'Welcome to Windscribe' ? '1.2rem 1.6rem' : '1.2rem 0rem',
          m: title === 'Welcome to Windscribe' ? '8px 0' : 'none',
          borderRadius: '8px',
          boxShadow: isSelected ? 'inset -4px 0 0 0 #58ff8d' : 'none',
          backgroundColor: isSelected ? 'rgba(84, 255, 138, 0.1)' : 'transparent',
          fontWeight: 500,
          color: title === 'Welcome to Windscribe' ? '#54ff8a' : 'rgba(255, 255, 255, 0.5)',
        }}
      >
        <Flex sx={{ gap: '1.6rem' }}>
          {icon && <Svg svg={icon} fill={'rgba(255, 255, 255, 0.5)'} />}
          {title}
        </Flex>
      </Flex>
    </a>
  )
}

function AccordionNavBarItem({ title, isSelected, children, icon }: NavBarItemProps) {
  const [isOpen, setIsOpen] = useState(isSelected)

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        borderRadius: '8px',
      }}
    >
      <Text
        onClick={() => setIsOpen(isOpen => !isOpen)}
        sx={{
          p: '1.2rem 0rem',
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'space-between',
          cursor: 'pointer',
          fontWeight: 500,
          color: isOpen ? 'white ' : 'rgba(255, 255, 255, 0.5)',
          transition: 'all 0.5s ease',
          ':hover': {
            color: 'white',
          },
          '&:hover svg path': {
            fill: 'white',
          },
        }}
      >
        <Flex sx={{ gap: '1.6rem' }}>
          {icon && <Svg svg={icon} fill={isOpen ? 'white' : 'rgba(255, 255, 255, 0.5)'} />}
          {title}
        </Flex>
        <Svg svg={DownArrow} fill={isOpen ? 'white' : 'rgba(255, 255, 255, 0.5)'} />
      </Text>
      <Flex
        sx={{
          flexDirection: 'column',
          overflow: 'hidden',
          transition: 'max-height 0.3s ease-in-out',
          maxHeight: isOpen ? '60rem' : 0,
        }}
      >
        {children}
      </Flex>
    </Flex>
  )
}

interface NavBarChildItemProps {
  title: string
  setIsNavBarOpen: Dispatch<SetStateAction<boolean>>
}

export function NavBarChildItem({ title, setIsNavBarOpen }: NavBarChildItemProps) {
  return (
    <a
      href={`#${title
        .replace(/[!"’#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/g, '')
        .replace(/\s+/g, '-')
        .toLowerCase()}`}
    >
      <Flex
        onClick={() => {
          setIsNavBarOpen(false)
        }}
        sx={{
          cursor: 'pointer',
          p: '1.2rem 0rem',
          ml: '4rem',
          mr: '1.6rem',
          alignItems: 'center',
          color: 'rgba(255, 255, 255, 0.5)',
          borderRadius: '8px',
          ':hover': {
            color: 'white',
          },
        }}
      >
        <Text>{title}</Text>{' '}
      </Flex>
    </a>
  )
}
