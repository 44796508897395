import React, { useEffect, useState, SVGAttributes } from 'react'
import { Box, ThemeUIStyleObject } from 'theme-ui'

export interface SvgProps extends SVGAttributes<unknown> {
  svg: React.ElementType
  fill?: string
  styles?: ThemeUIStyleObject
  descriptiveText?: string
  sx?: ThemeUIStyleObject
}

export default function Svg(props: SvgProps): React.ReactElement {
  const { svg, fill, styles = {}, sx = {}, descriptiveText = '', ...rest } = props
  const [svgStyles, setSvgStyles] = useState<ThemeUIStyleObject>({
    ...styles,
    ...sx,
    '& > path,g': {
      fill,
    },
  })

  useEffect(() => {
    setSvgStyles(prevStyles => ({
      ...prevStyles,
      '& > path,g': {
        fill,
      },
    }))
  }, [fill])

  return (
    <Box
      as={svg}
      role="img"
      // @ts-ignore
      alt={descriptiveText}
      title={descriptiveText}
      sx={svgStyles}
      {...rest}
    />
  )
}
