import React, { Dispatch, SetStateAction } from 'react'
import { Flex, Text } from 'theme-ui'
import WindscribeLogo from 'images/logo.svg'
import Svg from 'components/Svg'
import CloseIcon from 'images/MiscIcons/Close.svg'

interface NavBarHeadingProps {
  setIsNavBarOpen: Dispatch<SetStateAction<boolean>>
  shouldShowCloseButton: boolean
}

export default function NavBarHeading({
  shouldShowCloseButton,
  setIsNavBarOpen,
}: NavBarHeadingProps) {
  return (
    <Flex
      sx={{
        gap: '1.6rem',
        pb: [0, 0, '1.6rem'],
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Flex sx={{ gap: '1.2rem', alignItems: 'center' }}>
        <Svg svg={WindscribeLogo} />
        <Text sx={{ fontWeight: 400, color: 'rgb(255, 255, 255, 0.5)', fontSize: '1.4rem' }}>
          Employee Handbook
        </Text>
      </Flex>
      {shouldShowCloseButton && (
        <Svg
          sx={{ cursor: 'pointer', display: ['flex', 'flex', 'none'] }}
          svg={CloseIcon}
          onClick={() => {
            setIsNavBarOpen(false)
          }}
        />
      )}
    </Flex>
  )
}
